import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import last from 'lodash/last';
import first from 'lodash/first';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import cn from 'classnames';
import { getSecondsFromMidnight } from 'helpers/datetime';
import Stack from 'components/Stack';
import MealTooltip from 'components/RelatedData/MealTooltip';
import ActivityTooltip from 'components/RelatedData/ActivityTooltip';
import PillTooltip from 'components/RelatedData/PillTooltip';
import InjectionTooltip from 'components/RelatedData/InjectionTooltip';
import * as shapes from '../../shapes';
import DayTimeline from './DayTimeline';
import Reading from './Reading';
import ReadingTooltip from './ReadingTooltip';
import StackedReadingsTooltip from './StackedReadingsTooltip';
import RelatedData from './RelatedData';
import styles from './DailyReadingsDistribution.pcss';


class DailyReadingsDistribution extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const { readings } = props;
    if (!readings || !readings.length) {
      return null;
    }

    const { width } = state;
    const readingStackBaseWidth = 35;

    const sortedReadings = sortBy(readings, 'timestamp');
    const processedReadings = [];
    const stackedReadings = [];
    const separateReadings = [];

    forEach(sortedReadings, (reading, index) => {
      if (includes(processedReadings, reading)) {
        return;
      }

      const { timestamp } = reading;
      const nextReading = sortedReadings[index + 1];
      if (!nextReading) {
        separateReadings.push(reading);
        return;
      }

      const lastStack = last(stackedReadings);
      const lastStackedReading = last(lastStack);
      const lastStackedReadingTimestamp = get(lastStackedReading, 'timestamp');
      if (lastStackedReadingTimestamp
        && ((timestamp - lastStackedReadingTimestamp) / 86400 < ((readingStackBaseWidth + (lastStack.length - 1) * 5)) / width)
      ) {
        lastStack.push(reading);
        processedReadings.push(reading);
        return;
      }

      const { timestamp: nextTimestamp } = nextReading;
      const timeDiff = nextTimestamp - timestamp;
      if (timeDiff / 86400 > readingStackBaseWidth / width) {
        separateReadings.push(reading);
        processedReadings.push(reading);
        return;
      }

      if (!lastStack) {
        stackedReadings.push([reading, nextReading]);
        processedReadings.push(reading, nextReading);
      }

    });


    return {
      width,
      stackedReadings,
      separateReadings,
    };
  }

  static propTypes = {
    // Explicit props
    // eslint-disable-next-line react/no-unused-prop-types
    readings   : PropTypes.arrayOf(shapes.reading),
    relatedData: PropTypes.array.isRequired,
    conversion : PropTypes.object.isRequired,
    // Explicit actions
    onCellClick: PropTypes.func,
  };


  constructor() {
    super();

    this.containerElement = React.createRef();

    this.state = {
      tooltip         : null,
      width           : 0,
      stackedReadings : [],
      separateReadings: [],
    };
  }


  componentDidMount() {
    const { width } = this;
    this.setState({ width });
  }


  onShowTooltip(type, data, style) {
    this.setState({ tooltip: { type, data, style } });
  }


  onHideTooltip() {
    this.setState({ tooltip: null });
  }


  get width() {
    const boundingRect = this.containerElement.current.getBoundingClientRect();
    return boundingRect.width;
  }


  renderSeparateReading(reading) {
    const { originalTimestamp, timestamp, isActive } = reading;
    const secondsInDay = 86400;
    const left = `${getSecondsFromMidnight(reading.timestamp) / secondsInDay * 100}%`;
    const style = { left };

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        key={`reading-${timestamp}`}
        className={
          cn(
            styles.dailyMeasurementsDistribution__reading,
            {
              [styles['dailyMeasurementsDistribution__reading--isActive']]: isActive,
            },
          )
        }
        style={style}
        onClick={() => this.props.onCellClick(originalTimestamp || timestamp)}
        onMouseEnter={() => this.onShowTooltip('reading', reading, style)}
        onMouseLeave={() => this.onHideTooltip()}
      >
        <Reading reading={reading} />
      </div>
    );
  }


  renderSeparateReadings() {
    const { separateReadings } = this.state;
    return map(separateReadings, (reading) => this.renderSeparateReading(reading));
  }


  renderStackedReading(reading) {
    const { originalTimestamp, timestamp, isActive } = reading;

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        key={`reading-${reading.timestamp}`}
        className={
          cn(
            styles.dailyMeasurementsDistribution__reading,
            {
              [styles['dailyMeasurementsDistribution__reading--isActive']]: isActive,
            },
          )
        }
        onClick={() => this.props.onCellClick(originalTimestamp || timestamp)}
      >
        <Reading reading={reading} />
      </div>
    );
  }


  renderStackedReadings() {
    const { stackedReadings } = this.state;

    return map(stackedReadings, (stack) => {
      const firstReading = first(stack);
      const { timestamp } = firstReading;
      const secondsInDay = 86400;
      const left = `${getSecondsFromMidnight(timestamp) / secondsInDay * 100}%`;
      const style = { left };

      const activeElementIndex = findIndex(stack, (reading) => reading.isActive);

      return (
        <div
          key={`stacked-reading-${timestamp}`}
          className={styles.dailyMeasurementsDistribution__readingStack}
          style={style}
          onMouseEnter={() => this.onShowTooltip('stackedReadings', stack, style)}
          onMouseLeave={() => this.onHideTooltip()}
        >
          <Stack activeElementIndex={activeElementIndex}>
            { map(stack, (reading) => this.renderStackedReading(reading)) }
          </Stack>
        </div>
      );
    });
  }


  renderRelatedData() {
    const { relatedData } = this.props;
    return map(relatedData, (data) => {
      const { timestamp, carbs } = data;
      const secondsInDay = 86400;
      const left = `${getSecondsFromMidnight(timestamp) / secondsInDay * 100}%`;
      const style = { left };

      return (
        <div
          className={styles.dailyMeasurementsDistribution__relatedData}
          type="related-data"
          style={style}
          key={`${data.type}-${timestamp}-${carbs}`}
          onMouseEnter={() => this.onShowTooltip('relatedData', data, style)}
          onMouseLeave={() => this.onHideTooltip()}
        >
          <RelatedData
            data={data}
          />
        </div>
      );
    });
  }


  renderTooltip() {
    const { tooltip } = this.state;
    if (!tooltip) {
      return null;
    }

    const { type, data, style } = tooltip;

    if (type === 'reading') {
      return (
        <div className={styles.dailyMeasurementsDistribution__readingTooltip} style={style}>
          <ReadingTooltip reading={data} conversion={this.props.conversion} />
        </div>
      );
    }

    if (type === 'stackedReadings') {
      return (
        <div className={styles.dailyMeasurementsDistribution__readingStackTooltip} style={style}>
          <StackedReadingsTooltip readings={data} conversion={this.props.conversion} />
        </div>
      );
    }

    let relatedDataTooltip;
    switch (data.type) {
      case 'food':
        relatedDataTooltip = <MealTooltip meal={data} />;
        break;
      case 'activity':
        relatedDataTooltip = <ActivityTooltip activity={data} />;
        break;
      case 'pill':
        relatedDataTooltip = <PillTooltip pill={data} />;
        break;
      case 'injection':
        relatedDataTooltip = <InjectionTooltip injection={data} />;
        break;
      default:
        relatedDataTooltip = null;
    }

    return (
      <div className={styles.dailyMeasurementsDistribution__relatedDataTooltip} style={style}>
        { relatedDataTooltip }
      </div>
    );
  }


  render() {
    return (
      <div
        className={styles.dailyMeasurementsDistribution}
        ref={this.containerElement}
      >
        <DayTimeline />
        <div>
          { this.renderSeparateReadings() }
          { this.renderStackedReadings() }
          { this.renderRelatedData() }
          { this.renderTooltip() }
        </div>
      </div>
    );
  }

}


export default withStyles(styles)(DailyReadingsDistribution);
