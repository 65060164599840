import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import get from 'lodash/get';
import map from 'lodash/map';
import { formatDate } from 'helpers/datetime';
import MetricConversions from 'libs/MetricConversions';
import Widget from 'components/Widget';
import Avatar from 'components/Avatar';
import intlShape from 'shapes/intlShape';
import patientShape from 'shapes/patientShape';
import App from 'modules/App';
import Account from 'modules/Account';
import AmbulatoryGlucoseProfile from 'modules/AmbulatoryGlucoseProfile';
import CloudDrive from 'modules/CloudDrive';
import Hcp from 'modules/Hcp';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import MeasurementsTableWidget from '../MeasurementsTableWidget';
import GlucoseLevelDistributionWidget from '../GlucoseLevelDistributionWidget';
import CgmDistributionWidget from '../CgmDistributionWidget';
import SummaryTableWidgetReport from '../SummaryTableWidgetReport';
import TrendChartWidget from '../TrendChartWidget';
import CgmTrendChartWidget from '../CgmTrendChartWidget';
import AgpTrendChartWidget from '../AgpTrendChartWidget';
import AgpProfileWidget from '../AgpProfileWidget';
import PrintableAgpReport from '../PrintableAgpReport';
import PrintableAgpBgmReport from '../PrintableAgpBgmReport';
import DailyLogReport from '../DailyLogReport';
import BloodGlucoseConcentrationReport from '../BloodGlucoseConcentrationReport';
import KPIs from '../KPIs';
import GlucoseConcentrationLevelsWidget from '../GlucoseConcentrationLevelsWidget';
import PatientsBgDataReport from '../PatientsBgDataReport';
import PatientGestationalReport from '../PatientGestationalReport';
import styles from './Report.pcss';


class Report extends React.PureComponent {

  static propTypes = {
    // Explicit props
    reportsState       : PropTypes.object,
    isPatientMode      : PropTypes.bool,
    isReadOnly         : PropTypes.bool,
    activePatient      : patientShape,
    patients           : PropTypes.arrayOf(patientShape),
    phiSet             : PropTypes.object, // @TODO: shape
    phiSetDocumentId   : PropTypes.string,
    readings           : PropTypes.array, // @TODO: shape
    timeSeriesResources: PropTypes.array,
    standards          : PropTypes.shape({
      maxValue: PropTypes.number.isRequired,
      preMeal : PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
      postMeal: PropTypes.shape({
        highThreshold: PropTypes.number.isRequired,
        lowThreshold : PropTypes.number.isRequired,
      }),
    }),
    countrySettings       : PropTypes.object,
    clinicSettings        : PropTypes.object,
    highlightedDates      : PropTypes.arrayOf(PropTypes.string),
    renderPatientHeader   : PropTypes.func,
    renderFeaturesActions : PropTypes.func,
    renderContextAlerts   : PropTypes.func,
    prepareAgpPreview     : PropTypes.func,
    onActivatePatient     : PropTypes.func,
    highlightedReadings   : PropTypes.array,
    relatedData           : PropTypes.array,
    measurements          : PropTypes.array,
    // Implicit props
    direction             : App.shapes.direction,
    featureToggles        : PropTypes.arrayOf(PropTypes.string),
    activeClinicMembership: Account.shapes.clinicMembership,
    openModalId           : PropTypes.string,
    formValues            : PropTypes.object,
    measurementsValues    : PropTypes.object,
    metricsUnits          : PropTypes.object, // @TODO: Shape
    accountPatientProfile : PropTypes.object, // @TODO: Shape
    dashboardLayout       : PropTypes.arrayOf(PropTypes.oneOfType(
      [PropTypes.string, PropTypes.arrayOf(PropTypes.string)],
    )).isRequired,
    deviceMode                  : PropTypes.oneOf(constants.DEVICES_MODES),
    intl                        : intlShape,
    highlightedAverageGlucose   : PropTypes.number,
    highlightedStandardDeviation: PropTypes.number,
    calculationFormula          : PropTypes.oneOf([
      ...constants.CALCULATION_FORMULAS,
      ...constants.CALCULATION_FORMULAS_CGM,
    ]),
    resultsIsInProgress: PropTypes.bool,
    downloadCsv        : PropTypes.bool,
    hideEmptyRows      : PropTypes.bool,
    showDayDividers    : PropTypes.bool,
  };


  constructor(props) {
    super(props);

    this.metricConversions = new MetricConversions(props.metricsUnits);
  }


  get customRanges() {
    return get(this.props.reportsState.phiSet, 'customRanges') || map(constants.HOURS_BREAKPOINTS, (hb) => [...hb]);
  }


  /* eslint-disable react/no-unstable-nested-components */
  get widgets() {
    const { reportsState } = this.props;
    const startDate = moment.utc(reportsState.startDate);
    const endDate = moment.utc(reportsState.endDate);

    return {
      TrendChartWidget: () => {
        switch (this.props.deviceMode) {
          case 'BGM': return (
            <TrendChartWidget
              renderControls={false}
              key="TrendChartWidget"
              className="TrendChartWidget"
              phiSetDocumentId={reportsState.phiSetDocumentId}
              phiSet={reportsState.phiSet}
              data={this.statsData}
              relatedData={reportsState.relatedData}
              timeSeriesResources={reportsState.timeSeriesResources}
              measurements={reportsState.measurements}
              start={reportsState.start}
              end={reportsState.end}
              conversion={this.metricConversions.bloodGlucoseConcentration}
              direction={this.props.direction}
              standards={reportsState.standards}
              customRanges={this.customRanges}
              activePointTimestamp={reportsState.activeMeasurementTimestamp}
              isInProgress={false}
              isReadOnly
              highlightedReadings={reportsState.highlightedReadings}
              onSetActivePointTimestamp={() => null}
            />
          );
          case 'CGM': return (
            <CgmTrendChartWidget
              key="CgmTrendChartWidget"
              className="CgmTrendChartWidget"
              conversion={this.metricConversions.bloodGlucoseConcentration}
              direction={this.props.direction}
              standards={reportsState.standards}
              renderDatePresets={() => null}
              isInProgress={false}
              start={reportsState.start}
              end={reportsState.end}
              onDatesChange={() => null}
            />
          );
          case 'AGP': return (
            <AgpTrendChartWidget
              key="AgpTrendChartWidget"
              className="AgpTrendChartWidget"
              conversion={this.metricConversions.bloodGlucoseConcentration}
              direction={this.props.direction}
              standards={reportsState.standards}
              isInProgress={false}
              start={reportsState.start}
              end={reportsState.end}
              data={this.statsData}
            />
          );
          default: return null;
        }
      },
      MeasurementsTableWidget: () => (
        <MeasurementsTableWidget
          key="MeasurementsTableWidget"
          className="MeasurementsTableWidget"
          phiSet={reportsState.phiSet}
          conversion={this.metricConversions.bloodGlucoseConcentration}
          standards={reportsState.standards}
          readings={reportsState.data}
          timeSeriesResources={reportsState.timeSeriesResources}
          relatedData={reportsState.relatedData}
          customRanges={this.customRanges}
          activeReadingTimestamp={reportsState.activeMeasurementTimestamp}
          headerMessage={messages.headers.measurementsTable}
          isInProgress={reportsState.isInProgress}
          isReadOnly
        />
      ),
      GlucoseLevelDistributionWidget: () => (
        <GlucoseLevelDistributionWidget
          key="GlucoseLevelDistributionWidget"
          className="GlucoseLevelDistributionWidget"
          flagFilters={reportsState.flagFilters}
          standards={reportsState.standards}
          readings={reportsState.data}
          isInProgress={reportsState.isInProgress}
        />
      ),
      AgpProfileWidget: () => (
        <AgpProfileWidget
          key="AgpProfileWidget"
          className="AgpProfileWidget"
          conversion={this.metricConversions.bloodGlucoseConcentration}
          standards={reportsState.standards}
          start={reportsState.start}
          end={reportsState.end}
          isInProgress={reportsState.isInProgress}
        />
      ),
      CgmDistributionWidget: () => (
        <CgmDistributionWidget
          key="CgmDistributionWidget"
          className="CgmDistributionWidget"
          conversion={this.metricConversions.bloodGlucoseConcentration}
          standards={reportsState.standards}
        />
      ),
      SummaryTableWidgetReport: () => (
        <SummaryTableWidgetReport
          key="SummaryTableWidgetReport"
          className="SummaryTableWidgetReport"
          conversion={this.metricConversions.bloodGlucoseConcentration}
          standards={reportsState.standards}
          patientId={reportsState.patientId}
          readings={reportsState.data}
          customRanges={this.customRanges}
          isInProgress={reportsState.isInProgress}
        />
      ),
      PatientHeader     : () => this.renderPrintableHeader(),
      PrintableAgpReport: () => (
        <PrintableAgpReport
          key="PrintableAgpReport"
          className="PrintableAgpReport"
          conversion={this.metricConversions.bloodGlucoseConcentration}
          standards={reportsState.standards}
          firstName={get(this.props, 'accountPatientProfile.firstName', '')}
          lastName={get(this.props, 'accountPatientProfile.lastName', '')}
        />
      ),
      BloodGlucoseConcentrationReport: () => (
        <BloodGlucoseConcentrationReport
          key="BloodGlucoseConcentrationReport"
          className="BloodGlucoseConcentrationReport"
          conversion={this.metricConversions.bloodGlucoseConcentration}
          standards={reportsState.standards}
          calculationFormula={reportsState.calculationFormula}
          phiSet={reportsState.phiSet}
          measurements={reportsState.measurements}
          accountPatientProfile={{ ...this.props.accountPatientProfile, ...this.props.activePatient }}
          customRanges={this.customRanges}
        />
      ),
      DailyLogReportPrint: () => (
        <DailyLogReport
          key="DailyLogReportPrint"
          className="DailyLogReportPrint"
          conversion={this.metricConversions.bloodGlucoseConcentration}
          standards={reportsState.standards}
          calculationFormula={reportsState.calculationFormula}
          phiSet={reportsState.phiSet}
          accountPatientProfile={{ ...this.props.accountPatientProfile, ...this.props.activePatient }}
          startDate={startDate}
          endDate={endDate}
          readings={reportsState.data}
          relatedData={reportsState.relatedData}
          daysWithReadings={reportsState.daysWithReadings}
          daysWithoutReadings={reportsState.daysWithoutReadings}
          preMeal={reportsState.preMeal}
          postMeal={reportsState.preMeal}
          hideEmptyRows={this.props.hideEmptyRows}
          showDayDividers={this.props.showDayDividers}
          customRanges={this.customRanges}
        />
      ),
      GlucoseConcentrationLevelsWidget: () => (
        <GlucoseConcentrationLevelsWidget
          key="GlucoseConcentrationLevelsWidget"
          className="GlucoseConcentrationLevelsWidget"
          conversion={this.metricConversions.bloodGlucoseConcentration}
          standards={reportsState.standards}
          readings={reportsState.data}
          isInProgress={false}
        />
      ),
      PrintableAgpBgmReport: () => (
        <PrintableAgpBgmReport
          key="PrintableAgpBgmReport"
          className="PrintableAgpBgmReport"
          conversion={this.metricConversions.bloodGlucoseConcentration}
          standards={reportsState.standards}
          firstName={get({ ...this.props.accountPatientProfile, ...this.props.activePatient }, 'firstName', '')}
          lastName={get({ ...this.props.accountPatientProfile, ...this.props.activePatient }, 'lastName', '')}
          readings={reportsState.data}
          preMealReadings={reportsState.preMealReadings}
          postMealReadings={reportsState.postMealReadings}
          strictPreMealReadings={reportsState.strictPreMealReadings}
          strictPostMealReadings={reportsState.strictPostMealReadings}
          daysWithReadings={reportsState.daysWithReadings}
          daysWithoutReadings={reportsState.daysWithoutReadings}
          readingsWithoutFlag={reportsState.readingsWithoutFlag}
          glucoseConcentrationLevelBottomValues={constants.GLUCOSE_CONCENTRATION_LEVELS_BOTTOM_VALUES}
        />
      ),
      PatientsBgDataReport: () => (
        <PatientsBgDataReport
          downloadCsv={this.props.downloadCsv}
          key="PatientsBgDataReport"
          className="PatientsBgDataReport"
          conversion={this.metricConversions.bloodGlucoseConcentration}
          standards={reportsState.standards}
          readings={reportsState.data}
          relatedData={reportsState.relatedData}
          accountPatientProfile={{ ...this.props.accountPatientProfile, ...this.props.activePatient }}
          startDate={startDate}
          endDate={endDate}
        />
      ),
      PatientsGestationalReport: () => (
        <PatientGestationalReport
          key="PatientGestationalReport"
          className="PatientGestationalReport"
          accountPatientProfile={{ ...this.props.accountPatientProfile, ...this.props.activePatient }}
          conversion={this.metricConversions.bloodGlucoseConcentration}
          standards={reportsState.standards}
          phiSet={reportsState.phiSet}
          readings={reportsState.data}
          reportsDates={{ startDate, endDate, days: reportsState.days }}
        />
      ),
    };
  }


  get statsData() {
    const { reportsState } = this.props;
    const { statsData, data } = reportsState;
    return statsData || data;
  }


  renderFeatures() {
    const { clinicSettings, reportsState } = this.props;
    const {
      data,
      phiSet,
      preMealReadings,
      postMealReadings,
      daysWithReadings,
      daysWithoutReadings,
      readingsWithoutFlag,
      standards,
    } = reportsState;

    return (
      <KPIs
        key="KPIs"
        readings={data}
        preMealReadings={preMealReadings}
        postMealReadings={postMealReadings}
        standards={standards}
        clinicSettings={clinicSettings}
        conversion={this.metricConversions.bloodGlucoseConcentration}
        daysWithReadings={daysWithReadings}
        daysWithoutReadings={daysWithoutReadings}
        isInProgress={false}
        readingsWithoutFlag={readingsWithoutFlag}
        phiSet={phiSet}
      />
    );
  }


  renderPrintablePatient() {
    const { firstName, lastName } = this.props.activePatient || {};
    const { firstName: accountFirstName, lastName: accountLastName } = this.props.accountPatientProfile || {};

    return (
      <div className="col p-4">
        <p className={cn('text--h3', styles.reportInfo__label)}>
          <FormattedMessage {...messages.report.info} />
        </p>
        <p className="text--h1 mb-2">
          <span>
            <FormattedMessage {...messages.report.for} />
            <br />
          </span>
          { `${firstName || accountFirstName} ${lastName || accountLastName}` }
        </p>
      </div>
    );
  }


  renderPrintableHeader() {
    const { activeClinicMembership, reportsState } = this.props;
    const { startDate, endDate, days } = reportsState;
    const clinic = get(activeClinicMembership, 'clinic');

    return (
      <div key="patient-header-wrapper">
        {
          clinic
          && (
            <Widget
              key="clinic-header"
              isNoHeader
              className={styles.printableClinicDetailsWrapper}
            >
              <div className="row align-items-center">
                { this.renderPrintablePatient() }
                <div className={styles.printableClinicDetails}>
                  <Avatar
                    imgClassName={styles.printableClinicDetails__logo}
                    avatarImg={clinic.logo}
                  />
                  <p
                    className={cn('text--normal', styles.printableClinicDetails__info)}
                  >
                    { clinic.name }<br />
                    { clinic.street }<br />
                    { clinic.zipCode } { clinic.city }
                  </p>
                </div>
              </div>
            </Widget>
          )
        }
        <Widget key="patient-header" isNoHeader>
          <div className={styles.printableHeader}>
            <div className="row align-items-center">
              { this.renderPrintablePatient() }
            </div>
            <div>
              <div className={styles.printableHeaderFeatures}>
                { this.renderFeatures() }
              </div>
            </div>
            <div className={styles.reportInfo__time}>
              <div>
                <FormattedMessage {...messages.report.generated} />{ ' ' }
                { moment().format('ll') }
              </div>
              <div>
                { formatDate(startDate, 'll') } - { formatDate(endDate, 'll') }
                (<FormattedMessage {...messages.presets.days} values={{ number: days }} />)
              </div>
            </div>
          </div>
        </Widget>
      </div>
    );
  }


  renderWidgets() {
    if (!process.env.BROWSER) {
      return null;
    }

    const { dashboardLayout } = this.props;
    return map(dashboardLayout, (widget) => {
      if (Array.isArray(widget)) {
        return (
          <div className="row" key={`${widget.toString()}`}>
            {
              map(widget, (element) => {
                const items = element.split(';');
                return (
                  <div key={`item-${items[1]}`} className={items[0]}>
                    { this.widgets[items[1]] ? this.widgets[items[1]]() : null }
                  </div>
                );
              })
            }
          </div>
        );
      }

      return this.widgets[widget] ? this.widgets[widget]() : null;
    });
  }


  render() {
    return (
      <div>
        <div className={cn('widgets', 'widgets--noGutters', styles.root)}>
          { this.renderWidgets() }
        </div>
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  mode                        : selectors.mode(state),
  deviceMode                  : selectors.deviceMode(state),
  fromImports                 : selectors.fromImports(state),
  fromImportsRange            : selectors.fromImportsRange(state),
  aggregateBy                 : selectors.aggregateBy(state),
  groupBy                     : selectors.groupBy(state),
  calculationFormula          : selectors.calculationFormula(state),
  reportsState                : selectors.reportsState(state),
  direction                   : App.selectors.direction(state),
  featureToggles              : App.selectors.featureToggles(state),
  countrySettings             : Account.selectors.countrySettings(state),
  metricsUnits                : Account.selectors.metricsUnits(state),
  accountPatientProfile       : Account.selectors.patientProfileExtended(state),
  activeClinicMembership      : Account.selectors.activeClinicMembership(state),
  activePatient               : Hcp.selectors.activePatient(state),
  highlightedDates            : AmbulatoryGlucoseProfile.selectors.highlightedDates(state),
  highlightedAverageGlucose   : AmbulatoryGlucoseProfile.selectors.highlightedAverageGlucose(state),
  highlightedStandardDeviation: AmbulatoryGlucoseProfile.selectors.highlightedStandardDeviation(state),
  isFetchReadingsInProgress   : CloudDrive.selectors.isFetchReadingsInProgress(state),
});


const mapDispatchToProps = (dispatch) => ({
  onActivatePatient: (patient, clinicMembership) => dispatch(Hcp.actions.activatePatient(patient, clinicMembership)),
  prepareAgpPreview: (activePatient, phiSet, phiSetDocumentId, successAction) => dispatch(
    AmbulatoryGlucoseProfile.actions.prepareAgpPreview(activePatient, phiSet, phiSetDocumentId, successAction),
  ),
});


const ConnectedReport = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Report));


export default withStyles(styles)(ConnectedReport);
